import LocalizedStrings, {
  LocalizedStrings as LocalizedStringsGenericType,
} from 'react-localization'

/* ------------- Types ------------- */
export type StringsType = typeof enBaseStrings

export type LocalizedStringsType = LocalizedStringsGenericType<StringsType>

type SupportedLanguagesType = 'en'

/* ------------- Strings ------------- */
const enBaseStrings = {
  beta: 'Beta',
  installMobileApp: 'Install Mobile App',
  weSeeYoureOnMobileMessage:
    'The SiteAssist mobile app is a much better experience for mobile users!',
  openMobileApp: 'Open Mobile App',
  continueOnWeb: 'Continue on Web',
  goToDetails: 'Go To Details',
  permitDetails: 'Permit Details',
  permitSummaryLabel: 'Summary',
  permitSummaryPlaceholder: 'e.g. "Location - Works title"',
  extensionsRequested: 'Extension Requested',
  deleteDraft: 'Delete Draft',
  deleteDraftMessage: 'Are you sure you want to delete this draft?',
  deletePlanMessage: 'Are you sure you want to delete this plan?',
  deleteFileMessage: 'Are you sure you want to delete this file?',
  deleteFolderMessage: 'Are you sure you want to delete this folder?',
  deleteFolderConfirmation: 'Delete Folder Confirmation',
  deleteFileConfirmation: 'Delete File Confirmation',
  successfullyDeleted: 'Successfully Deleted',
  confirmSaveTemplate: 'Are you sure you want to save this template?',
  liveTemplateDescription:
    'Making this template live will allow this template to be used in permit requests.',
  defaultRequestorConfirmationText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  defaultApproverConfirmationText:
    'I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit.&nbsp;',
  postPermitChecksDisclaimerDescription:
    "This is the message that will show to the <span class='font-weight-bold'>Permit Holder</span> once the work is complete.",
  finalSignOffDisclaimerDescription:
    "This is the message that will show to the <span class='font-weight-bold'>Approver</span> once the work is complete.",
  hideTestProjects: 'Hide Test Projects',
  zoom: 'Zoom',
  showQRCode: 'Show QR Code',
  scanQRCode: 'Scan QR Code',
  scanQRCodeDescription: 'Scan a SiteAssist QR Code for easy actions',
  QRCode: 'QR Code',
  permitQRCodeDescription:
    'Scan this QR code with your phone camera to open the permit.',
  scanQRCodeToRecordAttendance: 'Scan QR Code to Record Attendance',
  scanQRCodeToRecordAttendanceDescription:
    'Please use your device’s camera to scan the QR code to record briefing attendance.',
  briefingAttendanceRecord: 'Briefing Attendance Record',
  briefingAttendanceRecordDescription:
    'Please select your role and submit your signature to capture your acknowledgement and understanding of the briefing disclaimer and contents of this checklist.',
  briefingAttendanceRecordExternalDescription:
    'Please enter your details in order to capture the briefing attendance record.',
  briefingAttendanceRecordExternalSigning:
    'Please submit your signature to capture your acknowledgement and understanding of the briefing disclaimer and contents of this checklist.',
  disclaimerDescription:
    "This is the message that will show to a <span class='font-weight-bold'>{0}</span> once the work is complete.",
  checkForUpdates: 'Check for Updates',
  alreadyUpToDate: 'You are already up to date',
  fireWatchTitle: 'There is a {0} minute {1} countdown after expiry time',
  enterNumber: 'Enter Number',
  userProperty: 'User Property',
  userProperties: 'User Properties',
  registerItemAuthGroupDescription:
    'Select the authorisation group for the user select property',
  selectAuthGroup: 'Select Authorisation Group',
  extendEndTimeError: 'Please choose a time after the end time of the permit',
  fireWatchDescription:
    'This means the permit can be closed {0} at the earliest.',
  preStart: 'Pre Start',
  aNewVersion: 'A new version of SiteAssist is available',
  selectDateAndTime: 'Select Date & Time',
  pleaseUpdate: 'Please update the app to continue using SiteAssist.',
  reviewAnswer: 'Review Answer',
  reviewSubmission: 'Review Submission',
  reviewIssuedAnswers: 'Review Issued Answers',
  viewDocument: 'View Document',
  viewDocuments: 'View all {0} Documents',
  viewDocumentToAcknowledge: 'View document to acknowledge',
  submitSignature: 'Submit Signature',
  projectViewer: 'Project Viewer',
  betaAccess: 'Beta access',
  answerRequiredQuestions: 'Answer the required questions for the Permit',
  checklistAnswerRequiredQuestions:
    'Answer the required questions for the Checklist',
  choosePermitHolder: 'Choose the Permit Holder',
  clone: 'Clone',
  clonePermit: 'Clone Permit',
  clonePermitDescription: 'Clone this permit to create a new permit draft',
  permitCloned: 'Permit has been cloned',
  pleaseSeachByNameOrEmail: 'Please search by name or email',
  pleaseSearchByTemplateName: 'Please search by template name',
  seachByNameOrEmail: 'Search by name or email',
  transferDetails: 'Transfer Details',
  rejectionDetails: 'Rejection Details',
  reasonForRejectionDots: 'Reason For Rejection',
  addSignature: 'Add Signature',
  assignment: 'Assignment',
  transferOwnershipOnBriefing: 'Transfer Permit Ownership on Briefing',
  basicInformation: 'Basic Information',
  basicInformationDescription: 'Enter some basic information about the Permit',
  checklistBasicInformationDescription:
    'Enter some basic information about the Checklist',
  showAll: 'Show All',
  hideAll: 'Hide All',
  invite: 'Invite',
  answer: 'Answer',
  answers: 'Answers',
  numberOfAnswers: 'Number of Answers',
  min: 'Min',
  max: 'Max',
  add: 'Add',
  addALocation: 'Add a location',
  addALevel: 'Add a level',
  request: 'Request',
  cancelPermit: 'Cancel Permit',
  cancelPermitDescription: 'Cancel the permit',
  preparePermit: 'Prepare Permit',
  downloadPermit: 'Download Permit',
  downloadChecklist: 'Download Checklist',
  downloadingPermit: 'Downloading Permit',
  downloadingChecklist: 'Downloading Checklist',
  permitDownloadComplete: 'Permit Download Complete',
  checklistDownloadComplete: 'Checklist Download Complete',
  downloadComplete: 'Download Complete',
  permitDownloadFailed: 'Permit Download Failed',
  checklistDownloadFailed: 'Checklist Download Failed',
  cloneChecklist: 'Clone Checklist',
  cloneChecklistDescription:
    'Clone this checklist to create a new checklist draft',
  checklistCloned: 'Checklist Cloned',
  downloadingReport: 'Downloading Report',
  reportDownloadComplete: 'Report Download Complete',
  exportSuccessMessage: 'Success! Click Download to download the export.',
  reportDownloadFailed: 'Report Download Failed',
  permitRegister: 'Permit Register',
  registers: 'Registers',
  register: 'Register',
  registerName: 'Register Name',
  updatedFrom: 'Updated from',
  itemCreated: 'Item Created',
  itemUpdated: 'Item Updated',
  itemCreatedViaCsv: 'Item Created via CSV',
  itemUpdatedViaCsv: 'Item Updated via CSV',
  updatedProperties: 'Updated Properties',
  addedProperties: 'Added Properties',
  permitRegisterDescription:
    'Download a .csv file of this project’s permit register over the selected date range.',
  reportType: 'Report Type',
  download: 'Download',
  downloadCSV: 'Download CSV',
  openInBrowser: 'Open in Browser',
  areYouSure: 'Are you sure?',
  unsavedChanges: 'Unsaved Changes',
  addOrRemoveTemplates: 'Add or Remove Permit Templates',
  addGroupMembers: 'Add a user to the Group',
  manageMembers: 'Manage Members',
  permissionLevel: 'Permission Level',
  help: 'Help',
  preview: 'Preview',
  uploadNewVersion: 'Upload New Version',
  rename: 'Rename',
  versionHistory: 'Version History',
  folder: 'Folder',
  file: 'File',
  modified: 'Modified',
  filePreview: 'File Preview',
  colour: 'Colour',
  newFolder: 'New Folder',
  editFolder: 'Edit Folder',
  renameFile: 'Rename File',
  renameFolder: 'Rename Folder',
  renameLocation: 'Rename Location',
  renameLevel: 'Rename Level',
  selectCsvImport: 'Select a CSV file to import or drag and drop it here',
  dragCsvFileToUpload: 'Drag CSV file to upload',
  attachmentInputDragPrompt: 'Or drag & drop file in this area',
  uploading: 'Uploading',
  uploadComplete: 'Upload Complete',
  successfullyUploadedItems: 'Successfully uploaded Items',
  registerUploadTimeout: 'Register upload timeout',
  row: 'Row',
  successfullyImportedRows: (rowNumber: number) =>
    `Successfully imported ${rowNumber} row${rowNumber > 1 ? 's' : ''}`,
  browseFile: 'Browse File',
  or: 'or',
  notFound: 'Not Found',
  enterEmail: 'Enter Email',
  couldNotRetrieveEmail: 'Could not retrieve email. Please try again',
  cancelReason: 'Cancel Reason',
  thisWillRemoveParticipant: 'This will remove the participant.',
  thisWillDeleteTheFollowingRole: 'This will delete the following role',
  reload: 'Reload',
  couldNotLoadSignature: 'Could not load signature.',
  permitToDig: 'Permit to Dig',
  wouldYouLikeToReject:
    'Would you like to Reject or Issue the Permit to Work Request?',
  wouldYouLikeToClose: 'Would you like to Close the Permit?',
  unauthorisedAction: 'You are not authorised to perform this action.',
  unauthorisedActions: 'You are not authorised to perform these actions.',
  unauthorized: 'UNAUTHORIZED',
  userAlreadyVerifiedAlert:
    'You have already set a password for this user, please log in using that password.',
  mapBoxStyleUrl: 'MapBox Style URL',
  mapCenterLatitude: 'Map Center Latitude',
  mapCenterLongitude: 'Map Center Longitude',
  mapZoom: 'Map Zoom',
  map: 'Map',
  openMap: 'Open Map',
  startHere: 'Start Here',
  options: 'Options',
  pleaseProvideNameForThisLocation: 'Please provide a name for this location.',
  pleaseProvideNameForThisLevel: 'Please provide a name for this level.',
  pleaseSpeakToSiteAssistMappingConfiguration:
    'Please speak to someone from SiteAssist to enable maps for your Project',
  mappingConfigurationSaved: 'Mapping Configuration Saved',
  openInNewTab: 'Open in new tab',
  openLinkedItem: 'Open linked item',
  maximumDuration: 'Maximum Duration',
  maxDurationInfo:
    'This means the permit end date must be within this timeframe.',
  confirmDeleteQuestionGroup:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> this question group? Your changes will not be saved until you save the template.</p>',
  confirmDeleteLocation:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> this location? Your changes will not be saved until you save locations.</p>',
  confirmDeleteBusinessUnit:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> business unit <span class="text-red text-bold">{0}</span>?</p>',
  approvedBy: 'Approved By',
  text: 'Text',
  signedOffBy: 'Signed Off By',
  approved: 'Approved',
  editProfile: 'Edit Profile',
  editOrganisation: 'Edit Organisation',
  createOrganisation: 'Create Organisation',
  testOrganisation: 'Test Organisation',
  fileFormatInvalid: 'File Format Invalid',
  allowOverride: 'Allow Override',
  formula: 'Formula',
  recalculateFormulas: 'Recalculate Formulas',
  formulasRecalculated: 'Formulas Recalculated',
  uniqueIdSystemControlled: 'Unique ID System Controlled',
  profile: 'Profile',
  profileDetails: 'Profile Details',
  na: 'N/A',
  editYourInformation: 'Edit your personal profile information',
  noEvents: 'There are no events for this permit yet',
  noVersions: 'There are no versions for this Template',
  searchPermits: 'Search Permits',
  approver: 'Approver',
  approvers: 'Approvers',
  approvePermit: 'Approve Permit',
  raisePermit: 'Raise Permit',
  raiseChecklist: 'Raise Checklist',
  title: 'Title',
  durationMinutes: 'Duration (Minutes)',
  minutes: 'Minutes',
  rejectedBy: 'Rejected By',
  awaitingFinalSignOff: 'Final Sign Off',
  finalSignOff: 'Final Sign Off',
  multipleFinalSignoffs: 'Multiple Final Sign-Offs',
  noPermissions: 'No Permissions',
  periodicCheck: 'Periodic Check',
  roles: 'Roles',
  templatePermitRoles: 'Permit Roles',
  templateChecklistRoles: 'Checklist Roles',
  roleName: 'Role Name',
  createNewTemplateRole: 'Create New Role',
  createPermitRole: 'Create Permit Role',
  createPermit: 'Create Permit',
  createChecklist: 'Create Checklist',
  editRole: 'Edit Role',
  newRole: 'New Role',
  checklistRole: 'Checklist Role',
  reports: 'Reports',
  exports: 'Exports',
  saveRole: 'Save Role',
  rolePermissions: 'Role Permissions',
  roleDescription: 'Role Description',
  viewOnMap: 'View on Map',
  viewOnPlan: 'View on Plan',
  viewPlans: 'View Plans',
  plans: 'Plans',
  plan: 'Plan',
  planName: 'Plan Name',
  drawOnMap: 'Draw on Map',
  what3Words: 'What 3 Words',
  draw: 'Draw',
  roleAccessDescriptions: {
    ADD_PARTICIPANT: 'Add permit participants',
    PERIODIC_CHECK_SUBMIT: 'Submit periodic checks',
    VIEW_ONLY: 'View permit access',
  },
  briefingText: 'Briefing Text',
  briefParticipants: 'Brief Participants',
  briefParticipantsChecklistDescription:
    'Brief participants onto the checklist',
  briefParticipantsPermitDescription: 'Brief participants onto the permit',
  briefingDisclaimer: 'Briefing Disclaimer',
  briefingDescription:
    'This briefing material must be shown to any new participants joining',
  briefAndCollectSignatures: 'Brief & Collect Signatures',
  briefExistingParticipants: 'Brief Existing Participants',
  briefExistingParticipantsDescription:
    'Brief participants already added to the permit',
  selectBriefingParticipants: 'Select Participants to Brief',
  confirmDeletePermitRole:
    'Are you sure you want to delete this role? This action can’t be undone.',
  deleteRole: 'Delete Role',
  deletePlan: 'Delete Plan',
  planDeleted: 'Plan Deleted',
  planPublished: 'Plan Published',
  permitRoleDeleted: 'Periodic Check Deleted',
  recurringCheck: 'RECURRING CHECK',
  completedChecks: 'Completed Checks',
  completedBy: 'Completed By',
  completed: 'Completed',
  toDo: 'To-do',
  periodicChecks: 'Periodic Checks',
  preStartTasks: 'Pre-Start Tasks',
  doPreStartTasks: 'Do Pre-Start Tasks',
  noChecksSubmitted: 'No checks submitted to date',
  doPeriodicCheck: 'Do New Check',
  seeAllChecks: 'See All Checks',
  periodicCheckSaved: 'Periodic Check Saved',
  periodicCheckDeleted: 'Periodic Check Deleted',
  periodicCheckTitle: 'Periodic Check Title',
  newPeriodicCheck: 'New periodic check',
  saveCheck: 'Save Check',
  deleteCheck: 'Delete Check',
  noPeriodicChecksText: "You don't have any Periodic Checks configured",
  viewAllPeriodicChecks: 'View All Periodic Checks',
  createCheck: 'Create Check',
  dateCreated: 'Date Created',
  delete: 'Delete',
  questionType: 'Question Type',
  viewParticipant: 'View Participant',
  editParticipant: 'Edit Participant',
  addParticipant: 'Add Participant',
  submitPeriodicChecks: 'Submit Periodic Checks',
  submitPostPermitChecks: 'Submit Post Permit Checks',
  requestExtensions: 'Request Extensions',
  removeParticipant: 'Remove Participant',
  participantLog: 'Participant Log',
  remove: 'Remove',
  reviewExtension: 'Review Extension',
  permitInitiation: 'Permit Initiation',
  permitApprove: 'Permit Approve',
  approveExtension: 'Approve Extension',
  extensionReason: 'Extension Reason',
  awaitingParticipantSignatures: 'Awaiting Participant Signatures',
  hideSignature: 'Hide Signature',
  showSignature: 'Show Signature',
  showPhotos: 'Show Photos',
  hidePhotos: 'Hide Photos',
  showLocation: 'Show Location',
  hideLocation: 'Hide Location',
  awaitingPostPermitChecks: 'Post Permit Checks',
  checksComplete: 'Checks Complete',
  closed: 'Closed',
  close: 'Close',
  confirm: 'Confirm',
  createRequestPermit: 'Sign & Request',
  createRegister: 'Create Register',
  updateRegisterItem: 'Update Register Item',
  reasonForUpdate: 'Reason for Update',
  addRegisterItem: 'Add Register Item',
  editRegister: 'Edit Register',
  registerCreated: 'Register Created',
  registerUpdated: 'Register Updated',
  registerItemCreated: 'Register Item Created',
  registerItemUpdated: 'Register Item Updated',
  registerItemDetails: 'Register Item Details',
  registerItem: 'Register Item',
  registerProperties: 'Register Properties',
  registerProperty: 'Register Property',
  createRequestTeamPermit: 'Request',
  date: 'Date',
  days: 'Days',
  numeric: 'Numeric',
  noPropertyHasBeenAddedYet: 'No property has been added yet',
  hours: 'Hours',
  enableDuration: 'Enabling this will enforce a maximum duration for Permits.',
  dateAndTime: 'Date & Time',
  noMoreResults: 'No more results.',
  moreActions: 'More Actions',
  daysShort: 'd',
  monthsShort: 'm',
  yearsShort: 'y',
  duplicatedImageDetectedModalDescription:
    'We’ve detected that this image had been used before on other permits listed below',
  objectDetectionWarningModalDescription:
    'We’ve detected that this image contains a potential hazard',
  openShape: 'Open Shape Detected',
  openShapeModalDescription:
    'Please close the polygon shape before proceeding (by pressing the "tick icon")',
  duplicatedImageDetected: 'Duplicated Image Detected',
  defaultErrorMessage: 'An unexpected error has occurred',
  errorMessage400: 'Request failed with status code 400',
  errorMessage401: 'Request failed with status code 401',
  errorMessage404: 'Not Found',
  errorMessage500: 'Request failed with status code 500',
  details: 'Details',
  discarded: 'Discarded',
  discardDraft: 'Discard Draft',
  draft: 'Draft',
  viewDraft: 'View Draft',
  createNewDraft: 'Create New Draft',
  draftIsOutdated: 'Draft is Outdated',
  draftIsOutdatedDescription:
    'This draft is not the latest version of the template and cannot be submitted',
  live: 'Live',
  pointOfWorks3Words: 'Point of Works What3Words',
  permitAreas: 'Permit Areas',
  pointOfWorks3WordsPlaceholder: "eg. '///snow.logic.chains'",
  pointOfWorks: 'Point of Works',
  activeDate: 'Active Date',
  startDate: 'Start Date',
  startDateAndTime: 'Start date & time',
  startTime: 'Start Time',
  createdDate: 'Created Date',
  endDate: 'End date',
  endDateAndTime: 'End date & time',
  endTime: 'End Time',
  reject: 'Reject',
  timeUntilPermitExpires: 'Time until permit expires',
  thePermitWasRejected:
    'The Permit Request was rejected. Please see below for more information.',
  thePermitWasCancelled:
    'The Permit Request was cancelled. Please see below for more information.',
  rejectPermit: 'Reject Permit',
  rejectionReason: 'Rejection reason',
  rejectPermitExtension: 'Reject Permit Extension',
  reasonForExtension: 'Reason for Extension',
  reasonForExtensionRequest: 'Reason for Extension Request',
  reasonForCancellation: 'Reason for cancellation',
  cancellationReason: 'Cancellation Reason',
  reasonForCancelDots: 'Reason for Cancel...',
  permitExtended: 'Permit Extended',
  permitExtensionRejected: 'Permit Extension Rejected',
  newEndDateAndTime: 'New End Date & Time',
  permitRejected: 'Permit Rejected',
  permitExtendApproveDeclaration:
    'A request has been made to extend the permit to ',
  permitExtendApproveDeclaration1: 'A request has been made from ',
  permitExtendApproveDeclaration2: ' to extend the permit to ',
  extendedDate: 'Extended Date',
  rejectionReasonDescription:
    'Please provide the reason that this request has been rejected.',
  cancelPermitReason: 'Please provide the reason for cancelling the permit.',
  issuePermit: 'Issue Permit',
  projectCode: 'Project Code',
  nameOfProject: 'Name of the project',
  nameOfGroup: 'Name of the group',
  generalInformation: 'General information',
  generalInformationPlaceholder:
    'Enter any useful information about this project',
  unknownAddress: 'Unknown Address',
  enterAddress: 'Enter address',
  enterPostcode: 'Enter postcode',
  code: 'Code',
  enterCity: 'Enter City',
  city: 'City',
  postcode: 'Postcode',
  address: 'Address',
  projectName: 'Project Name',
  issue: 'Issue',
  approve: 'Approve',
  edit: 'Edit',
  view: 'View',
  viewRequestedChanges: 'View Requested Changes',
  viewReason: 'View Reason',
  viewDetails: 'View Details',
  permitStart: 'Permit Start',
  permitEnd: 'Permit End',
  email: 'Email',
  emailAddress: 'Email',
  emailNotificationSent: 'Email Notification Sent',
  ends: 'Ends',
  enterAnswer: 'Enter Answer',
  expired: 'Expired',
  expiresIn: 'Expires In',
  fileSize: 'This file is too big',
  fileWriteAccess: 'File write access',
  fileReadAccess: 'File Read access',
  cameraAndfileReadAccess: 'Camera and File Read access',
  cameraAccess: 'Camera access',
  fileWriteAccessMessage:
    'SiteAssist needs file write access to save your signature',
  fileReadAccessMessageSignature:
    'SiteAssist needs file read access to save your signature',
  fileReadAccessMessageProof:
    'SiteAssist needs file read access to upload proofs',
  cameraAndFileReadAccessMessage:
    'SiteAssist needs camera and file read access to upload proofs',
  cameraAccessMessage: 'SiteAssist needs camera access to capture proof',
  maximumRowsCSVExportError: 'Maximum of 50,000 rows can be downloaded',
  fillOutAllQuestions: 'Please fill out all questions',
  pleaseFillAllFieldsCorrrectly: 'Please fill all fields correctly',
  forgotPassword: 'Forgot Password',
  changePassword: 'Change Password',
  confirmPassword: 'Confirm Password',
  gatewayTimeoutError: 'API is unreachable right now',
  genericError: 'Error processing request',
  noAccessToProjectMessage:
    'You are not a member of this project.\nPlease speak to someone to add you to the project.',
  hoursShort: 'h',
  minsShort: 'm',
  secondsShort: 's',
  issued: 'Issued',
  issuedBy: 'Issued by',
  location: 'Location',
  locationOptions: 'Location Options',
  geoLocation: 'Geolocation',
  checkinLocation: 'Check In Location',
  updated: 'Updated',
  deleted: 'Deleted',
  created: 'Created',
  locationNotUpdatedRecently: 'Location not updated recently, please try again',
  locationsSaved: 'Locations Saved',
  signedBy: 'Signed by',
  duration: 'Duration',
  locationOfWorks: 'Location of Works',
  loggingIn: 'Logging in',
  login: 'Log in',
  ssoRedirectMessage: `You will now be directed to your company's login page. Please use your company credentials to log in.`,
  logout: 'Logout',
  logo: 'Logo',
  incrementalId: 'Incremental ID',
  prefix: 'Prefix',
  delimiter: 'Delimiter',
  incrementByParent: 'Increment by Parent',
  logoutMessage: 'You will be returned to the login screen.',
  organisationCreated: 'Organisation Created',
  organisationUpdatedSuccessfully: 'Organisation Updated Successfully',
  myPermits: 'My Permits',
  rectangle: 'Rectangle',
  ellipse: 'Ellipse',
  polygon: 'Polygon',
  closeShape: 'Close Shape',
  allPermits: 'All Permits',
  allChecklists: 'All Checklists',
  drafts: 'Drafts',
  next: 'Next',
  skip: 'Skip',
  alert: 'Alert',
  singleSignOnUserAlert:
    'This user is configured for Single-Sign using your corporate system. Please speak to your IT support team about resetting you password',
  proceedWithoutAssignAlert: 'You must assign someone before proceeding',
  mustBePartOfProjectPermitCreate:
    'You must be added to a project prior to creating a permit. Please contact your project admin to be added to the project.',
  mustBePartOfProjectChecklistCreate:
    'You must be added to a project prior to creating a checklist. Please contact your project admin to be added to the project.',
  noProjectsDashboard:
    'You must be added to a live project to view the organisation dashboard. Please contact your Project Admin to be added to a project. If you are part of test projects you can see their dashboard in the project page.',
  youAreOffline: 'You are Offline',
  update: 'Update',
  ok: 'OK',
  okay: 'Okay',
  leave: 'Leave',
  open: 'Open',
  opened: 'Opened',
  password: 'Password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  pending: 'Pending, (You have signed)',
  permitDuration: 'Permit Duration',
  permitQuestions: 'Permit Questions',
  checklistQuestions: 'Checklist Questions',
  postPermitQuestions: 'Post Permit Questions',
  authPersonQuestions: 'Authorised Person Questions',
  finalSignOffChecks: 'Final Sign Off Checks',
  permitType: 'Permit Type',
  pleaseSelect: 'Please Select',
  pleaseSelectDateRange: 'Please select a date range',
  selectRole: 'Select Role',
  selectUser: 'Select User',
  briefAndSign: 'Brief & Sign',
  project: 'Project',
  acceptTransfer: 'Accept Transfer',
  support: 'Support',
  supportInfo: "Message us and we'll be right with you",
  proofRequired: 'Proof Required',
  documentRequired: 'Document Required',
  documentOptional: 'Document Optional',
  signature: 'Signature',
  signatureOptional: 'Signature Optional',
  signatureRequired: 'Signature Required',
  mustUploadProof: 'You must upload a proof for this question',
  mustUploadSignature: 'You must upload your signature for this question',
  tapToEditSignature: 'Tap to edit signature',
  clearSignature: 'Clear Signature',
  signSpotInstruction: 'Sign within the dotted lines',
  requiresProof: 'Requires Proof',
  requiresAdditionalInformation: 'Requires Additional Information',
  AdditionalInformation: 'Additional Information',
  questionAdditionalInformation: (optional: boolean) =>
    `Additional Information ${optional ? '(Optional)' : ''}`,
  acceptedValues: 'Accepted Values',
  value: 'Value',
  validAnswer: 'Valid Answer',
  validAnswerOption: 'Enter A Valid Answer Option',
  invalidAnswer: 'Invalid Answer',
  invalidAnswerOption: 'Enter An Invalid Answer Option',
  invalidAnswerDescription: 'Invalid Answer Description',
  showInfo: 'Show Info',
  hideInfo: 'Hide Info',
  automatedEvent: 'Automated Event',
  invalidAnswerExplanation:
    'Sorry, this Permit Request cannot be processed if the answer to this question is',
  proofOptional: 'Proof optional',
  published: 'Published',
  enable: 'Enable',
  enableLive: 'Enable (Live)',
  templatePublished: 'Template Published',
  templateUnpublished: 'Template Unpublished',
  locationHierarchyDisabled: 'Location Hierarchy Disabled',
  locationHierarchyEnabled: 'Location Hierarchy Enabled',
  enableLocationHierarchy: 'Enable Location Hierarchy',
  disableLocationHierarchy: 'Disable Location Hierarchy',
  locationEnableConfirmationMessage:
    'Enabling Location Hierarchy for this project will push these changes to live. This will enable all those working on this project to see these changes.',
  locationDisableConfirmationMessage:
    'Disabling Location Hierarchy for this project will hide these changes. This will disable visibility of Location Hierarchy for all those working on this project.',
  locationRenamed: 'Location Renamed',
  locationArchived: 'Location Archived',
  areYouSureArchiveLocation: 'Are you sure you want to archive?',
  locationArchiveConfirmationText: `By archiving, you will no longer be able to edit. However, you can
  still view (read-only) archived items by selecting 'Archived' in the
  Status Filter.`,
  templateArchived: 'Template Archived',
  templateUnarchived: 'Template Unarchived',
  question: 'Question',
  acknowledgeQuestionPrompt: 'Please review and acknowledge this document',
  questionGroup: 'Question Group',
  questions: 'Questions',
  questionComponents: 'Question Components',
  selectQuestionType: 'Select Question Type',
  rejected: 'Rejected',
  requestExtension: 'Request Extension',
  requestExtensionDescription: 'Request an extension for the Permit duration',
  requestedExtension: 'Extension Requested',
  approvedExtension: 'Extension Approved',
  rejectedExtension: 'Extension Rejected',
  requestPermit: 'Request Permit',
  requestPermitAgain: 'Request Permit Again',
  requestSummary: 'Request Summary',
  requested: 'Requested',
  awaitingApproval: 'Awaiting Approval',
  awaitingApprovalNotification: 'Awaiting Approval Notification',
  approvalRequest: 'Approval Request',
  permitHolder: 'Permit Holder',
  permitHolderChanged: 'Permit Holder Changed',
  initiatedBy: 'Permit Author',
  analytics: 'Analytics',
  analyticsChatbot: 'Analytics Chatbot',
  permitHolderTel: 'Permit Holder Tel',
  uploadingData: 'Uploading Data',
  addingParticipants: 'Adding Participants',
  briefingParticipant: 'Briefing Participant',
  briefingParticipants: 'Briefing Participants',
  reset: 'Reset',
  select: 'Select',
  selectParticipants: 'Select Participants',
  participant: 'Participant',
  participantBriefed: 'Participant Briefed',
  participants: 'Participants',
  addParticipants: 'Add Participants',
  addParticipantsDescription: 'Add additional participants to the permit',
  addParticipantsToRoleDescription:
    'If the user is already on SiteAssist you can search for them here. If not they can be added separately via their name and Company',
  orAddParticipantsManually: 'Or, Add Participants Manually',
  addParticipantsManuallyDescription: `You will collect the Participant signatures by tapping Done`,
  selectPermitType: 'Select Permit Type',
  selectChecklistType: 'Select Checklist Type',
  selectPermitTypes: 'Select Permit Types',
  selectPermitStatus: 'Select Permit Status',
  selectPermitDateRange: 'Select Permit Date Range',
  selectProject: 'Select Project',
  selectProjects: 'Select Projects',
  filterPermits: 'Filter Permits',
  checklistFilter: 'Checklists Filter',
  sendEmail: 'Send Email',
  resendEmail: 'Re-send Email',
  sign: 'Sign',
  sendRequest: 'Send Request',
  signed: 'Signed',
  participantSigned: 'Participant Signed',
  participantsCreated: 'Participants Created',
  participantsBriefed: 'Participants Briefed',
  signedVia: 'Signed via',
  signing: 'Signing',
  started: 'Started',
  starts: 'Starts',
  start: 'Start',
  end: 'End',
  ended: 'Ended',
  status: 'Status',
  selectStatus: 'Select Status',
  description: 'Description',
  projectDocuments: 'Project Documents',
  saveTemplate: 'Save Template',
  template: 'Template',
  templateName: 'Template Name',
  templateTitle: 'Template Title',
  templateDescription: 'Template Description',
  saveDraft: 'Save Draft',
  saveNewDraft: 'Save New Draft',
  updateDraft: 'Update Draft',
  saveLocations: 'Save Locations',
  siteLocations: 'Site Locations',
  cannotEditLocationsMessage:
    'You cannot edit locations, only Project Admins are allowed to edit Site Locations',
  cannotAddSubLocation: 'Cannot Add Sub Location',
  cannotAddSubLocationMessage:
    'You cannot add a sub-location to a location with a plan',
  cannotUploadPlan: 'Cannot Upload Plan',
  cannotUploadPlanMessage:
    'You cannot upload a plan to a location with sub-locations',
  siteLocation: 'Site Location',
  selectLocation: 'Select Location',
  selectZone: 'Select Zone',
  selectZones: 'Select Zones',
  selectSubZone: 'Select Sub Zone',
  enableProjectLocations: 'Enable Project Locations',
  addLocation: 'Add Location',
  editMember: 'Edit Member',
  addMember: 'Add Member',
  editTemplates: 'Edit Templates',
  editTemplateName: 'Edit Template Name',
  changeTemplates: 'Change Templates',
  createTemplate: 'Create Template',
  moveTemplate: 'Move Template',
  move: 'Move',
  succesfullyMovedTemplate: 'Successfully moved template',
  addUsers: 'Add Users',
  projectUsers: 'Project Users',
  createSupportUser: 'Create Support User',
  newUserInvitedToProjectPermissionsInfo:
    'If the user needs additional permissions, please ask your Project Admin or get in touch with us',
  toggleProjectLocationInfo:
    'Turning on project locations will enable the below Location/Area heirarchy in the mobile app.  Turning this off will allow for free text locations in mobile app actions and the Location/Area heirarchy below will be ignored.',
  addToTemplate: 'Add to Template',
  addToProject: 'Add to Project',
  removeFromProject: 'Remove from Project',
  removeFile: 'Remove File',
  maxSize15mb: 'Max size 15mb',
  enterDocumentName: 'Enter Document Name',
  documentName: 'Document name',
  permitTimeline: 'Permit Timeline',
  checklistTimeline: 'Checklist Timeline',
  timeline: 'Timeline',
  projectTimeline: 'Project Timeline',
  summary: 'Summary',
  permitSummary: 'Permit Summary',
  checklistSummary: 'Checklist Summary',
  textSection: 'Text Section',
  time: 'Time',
  unpublished: 'Unpublished',
  unpublish: 'Unpublish',
  disable: 'Disable',
  disabled: 'Disabled',
  userDisabled: 'User Disabled',
  disableUserWarning: 'This will disable the user within this Project ONLY.',
  disableUserOrganisationWarning:
    'This will disable the user within the whole Organisation.',
  reactivateUser: 'Reactivate User',
  changeAuthGroups: 'Change Authorisation Groups',
  reactivateUserMessage:
    'This will reactivate the user within this project ONLY.',
  userSettingsOnOtherProjectsUnchanged:
    'Their settings on other projects will be unaffected.',
  unsigned: 'Unsigned',
  upTo: 'Up to {0}',
  upload: 'Upload',
  uploadPlan: 'Upload Plan',
  uploadFailed: 'Upload Failed',
  planUploaded: 'Plan Uploaded',
  publishPlan: 'Publish Plan',
  yesPlease: 'Yes please',
  noThanks: 'No, thanks',
  updateItem: 'Update Item',
  addItem: 'Add Item',
  saveProjectPlanAreasDescription:
    'Would you like to save your zones before you close/exit?',
  publishPlanDescriptionModal:
    'Once published, the zones on this plan will not be editable.',
  publishPlanDescriptionModal2:
    'Please ensure there are no overlapping zones before publishing.',
  thisPermitWillBeAppliedToSelectedZone:
    'This permit will be applied to the zone you have selected',
  saveSelectedProjectPlanAreaDescription:
    'Save to continue raising this permit, or cancel to edit this selection.',
  uploadFile: 'Upload File',
  uploadImage: 'Upload Photo',
  uploadProof: 'Upload Proof',
  uploadAPhoto: 'Upload a Photo',
  proofPhoto: 'Proof Photo',
  camera: 'Camera',
  uploadDocument: 'Upload Document',
  uploadedBy: 'Uploaded by',
  uploaded: 'Uploaded',
  addDocument: 'Add Document',
  changePermissions: 'Change Permissions',
  templateSettings: 'Template Settings',
  questionBuilder: 'Question Builder',
  templateBuilder: 'Template Builder',
  questionBuilderDescription:
    'Use the Question Builder to create questions that users have to complete at each stage.',
  workComplete: 'Work Complete',
  closePermit: 'Close Permit',
  closePermitDescription:
    'Close the Permit so that it can go to Final Sign-off',
  performChecks: 'Perform Checks',
  performChecksDescription:
    'Do Post Permit Checks so that it can go to Final Sign-off',
  declareWorkComplete: 'Declare Work Complete',
  workCompleteDescription:
    'Declare work complete so that the permit can be closed off',
  declareWorkCompleteParagraph:
    'Are you sure works are complete? If you proceed, your permit will no longer be open.',
  closePermitParagraph:
    'Are you sure? This Permit will no longer be open and work has to stop immediately.',
  you: 'You',
  hi: 'Hi',
  save: 'Save',
  editThisZone: 'Edit this Zone',
  deleteZone: 'Delete Zone',
  exit: 'Exit',
  planSaved: 'Plan Saved',
  selectedZones: 'Selected Zones',
  zones: 'Zones',
  duplicateZoneModalDescription:
    'You have 2 or more zones with the same name, zone labels should be unique. Please edit this before saving and exiting.',
  duplicateZoneDescription: 'Zone name is duplicated',
  duplicateZones: 'Duplicate Zones',
  createZonesNow: 'Create Zones Now',
  createZonesNowDescription:
    'You do not need to set up all of your zones now, you can save progress and finish another time.',
  proceedCreateZonesNow:
    'Proceed to begin, or exit to return to Locations Hierarchy.',
  proceed: 'Proceed',
  zoneLabel: 'Zone Label',
  max20Letters: 'Max 20 letters',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  addedUserToProject: 'Added User to Project',
  removedUserFromProject: 'Removed User from Project',
  activatedProjectMember: 'Activated Project Member',
  disabledProjectMember: 'Disabled Project Member',
  updatedProjectPermissions: 'Updated User Permissions – Project',
  addedUserToOrganisation: 'Added User to Organisation',
  changedProfileInformation: 'Changed Profile Information',
  updatedOrganisationPermissions: 'Updated User Permissions – Organisation',
  activatedOrganisationMember: 'Activated Organisation Member',
  disabledOrganisationMember: 'Disabled Organisation Member',
  activatedChecklistVersion: 'Activated Checklist Version',
  createdChecklistVersion: 'Created Checklist Version',
  updatedChecklistTemplate: 'Updated Checklist Template',
  createdPermitTemplate: 'Created Permit Template',
  updatedPermitTemplate: 'Updated Permit Template',
  createdPermitTemplateVersion: 'Created Permit Version',
  activatedPermitTemplateVersion: 'Activated Permit Version',
  updatedPermitTemplateVersion: 'Updated Permit Version',
  addedUserToAuthGroup: 'Added User to Auth Group',
  removedUserFromAuthGroup: 'Removed User from Auth Group',
  updatedAuthGroupPermissions: 'Updated Auth Group Permissions',
  addedPermitTemplate: 'Added Permit Template',
  updatedProjectPermitSettings: 'Updated Permit Settings – Project',
  updatedPermitApprovalSettings: 'Updated Permit Approval Settings',
  completeYourProfile: 'Complete Your Profile',
  digitalSignature: 'Digital signature',
  digitalSignatureInfo:
    'A Signature is required to Issue Permits. Once added it will be saved to your Profile.',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterCompany: 'Enter Company',
  enterPhone: 'Enter Phone',
  enterName: 'Enter Name',
  enterNote: 'Enter Note',
  enterReason: 'Enter Reason',
  telephoneNumber: 'Telephone number',
  complete: 'Complete',
  enterPhoneNumber: 'Enter phone number',
  completeProfile:
    'Hi <strong>{0}</strong>, you’ve been added as an <span class="text-bold">{1}</span> in the <span class="text-bold">{2}</span> Organisation. Please confirm a few details to get started.',
  completeProfileMobile:
    'Hi {0}, you’ve been added as an {1} in the {2} Organisation. Please confirm a few details to get started.',
  firstName: 'First Name',
  company: 'Company',
  lastName: 'Last Name',
  lastUpdated: 'Last Updated',
  lastModified: 'Last Modified',
  noPermits: 'No permits were found',
  noChecklists: 'No checklists were found',
  noPlans: 'No Plans',
  noPlansDescription:
    'No technical plans have been uploaded yet. To upload a new plan, first create a location (via Locations Hierarchy) and upload a plan for that location.',
  noPlansDescription2:
    "View our 'How to' guide for more help, or go to Locations hierarchy now to upload a plan.",
  howToGuide: '`How` to guide',
  locationsHierarchy: 'Locations Hierarchy',
  noResultsFound: 'No results were found',
  noResultsFoundPleaseChangeSearch:
    'No results were found, please change search',
  confirmDeleteUser:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0} {1}</span>? This action can not be undone.',
  confirmDeleteMember:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0} {1}</span> from group <span class="text-bold">{2}</span>? This action can not be undone.',
  confirmDeleteGroup:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0}</span>? This action can not be undone.',
  confirmResendInviteUser:
    'Are you sure you want to <span class="text-bold">resend</span> an invite to <span class="text-bold">{0} {1}</span>?',
  emailMustBeValid: 'Email must be valid',
  emailIsRequired: 'Email address is required',
  weHaveSentEmail: "We've sent you an email",
  resetPasswordCheckInbox: 'Check your inbox to finish resetting your password',
  passwordRequired: 'Password is required',
  passwordsMustMatch: 'Passwords must match',
  resetPassword: 'Reset Password',
  passwordResetSuccess: 'Password reset successfully!',
  passwordResetSuccessNextAction:
    'You can now log into the web and mobile apps with your new password',
  missingToken: 'Missing token',
  sending: 'Sending',
  requestAPermitTitle: 'Request a Permit',
  signAndSubmit: 'Sign and Submit',
  signAndReject: 'Sign and Reject',
  submit: 'Submit',
  postPermitChecksDisclaimerTitle: 'Post Permit Checks Disclaimer',
  postPermitChecksRequest: 'Post Permit Checks Request',
  postPermitChecksInfo: 'A participant should perform post permit checks',
  postPermitCheckTimeRemainingInfo:
    'Time remaining before the permit holder can perform post permit checks',
  completePostPermitChecks: 'Complete Post Permit Checks',
  permitContacts: 'Permit Contacts',
  postPermitChecksDisclaimerForRequesterTitle:
    'Post Permit Checks Disclaimer for Requester',
  postPermitChecksGeolocationWebTooltip:
    'Post Permit Checks for this permit template must be completed on a mobile device',
  disclaimer: 'Disclaimer',
  countdown: 'Countdown',
  countdownDescription:
    'The Requester will have to wait for the countdown to finish before they can finish post permit checks.',
  countdownTime: 'Countdown Time',
  countdownText:
    'Set a period of time which must pass before post permit checks can be complete.',
  countdownName: 'Countdown Name',
  postPermitChecksCountdown: 'Post permit checks countdown',
  postPermitChecksDisclaimerText:
    'I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit.',
  postPermitChecks: 'Post Permit Checks',
  postPermitChecksComplete: 'Post Permit Checks Complete',
  bySigningText:
    'By signing this you are requesting a permit.\n' +
    'We will send it to your project admin so that they can review it.',
  extendPermit: 'Extend Permit',
  extend: 'Extend',
  finalSignOffDisclaimerTitle: 'Final Sign Off Disclaimer',
  finalSignOffDisclaimerText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  processing: 'Processing',
  confirmSignature: 'Confirm Signature',
  acceptPermitTransferAcknowledge:
    'By signing this transfer you acknowledge your responsibility as the new Permit Holder.',
  permits: 'Permits',
  checklists: 'Checklists',
  checklist: 'Checklist',
  checklistId: 'Checklist ID',
  permitUniqueCode: 'Permit Unique Code',
  checklistUniqueCode: 'Checklist Unique Code',
  projectTemplates: 'Project Templates',
  templateUniqueCode: 'Template Unique Code',
  organisation: 'Organisation',
  linkedOrganisations: 'Linked Organisations',
  link: 'Link',
  registerTemplate: 'Register Template',
  selectDestinationOrganisation: 'Select destination organisation',
  sourceOrganisation: 'Source Organisation',
  destinationOrganisation: 'Destination Organisation',
  organisationLinkType: 'Link Type',
  distanceToPermit: 'Distance to Permit',
  permitTemplates: 'Permit Templates',
  checklistTemplates: 'Checklist Templates',
  registerTemplates: 'Register Templates',
  checklistTemplate: 'Checklist Template',
  checklistType: 'Checklist Type',
  checklistDetails: 'Checklist Details',
  uniqueCode: 'Unique Code',
  uniqueId: 'Unique ID',
  mapPOC: 'Map POC',
  newestFirst: 'Newest First',
  oldestFirst: 'Oldest First',
  createdOnAscending: 'Created (oldest first)',
  createdOnDescending: 'Created (newest first)',
  startsOnAscending: 'Starts (oldest first)',
  startsOnDescending: 'Starts (newest first)',
  endsOnAscending: 'Ends (oldest first)',
  endsOnDescending: 'Ends (newest first)',
  dashboard: 'Dashboard',
  projects: 'Projects',
  metricName: 'Metric Name',
  permitTemplate: 'Permit Template',
  sortBy: 'Sort By',
  multipleProjects: 'Multiple Projects',
  multipleTemplates: 'Multiple Templates',
  multipleStatuses: 'Multiple Statuses',
  multipleLocations: 'Multiple Locations',
  permitClosed: 'Permit Closed',
  all: 'All',
  allProjects: 'All Projects',
  projectsSelected: 'Projects Selected',
  permitTypesSelected: 'Permit Types Selected',
  statusesSelected: 'Statuses Selected',
  selectAll: 'Select All',
  deselectAll: 'Deselect All',
  datesSelected: 'Date(s) Selected',
  dateUploaded: 'Date Uploaded',
  Creator: 'Creator',
  permitTypes: 'Permit Types',
  permitStatus: 'Permit Status',
  filtersApplied: 'Filters Applied',
  clear: 'Clear',
  clearAll: 'Clear all',
  nothingSelected: 'Nothing selected',
  filter: 'Filter',
  noProjectsWithProjectAdminRole:
    'You are not a Project Admin in any Projects.',
  projectsWithProjectAdminRoleOrAuthPersonRole:
    'You are not a Project Admin or Permit Approver in any projects',
  postPermitCountdownFinished: 'Post Permit Checks Countdown Finished',
  enterTime: 'Enter Time',
  name: 'Name',
  type: 'Type',
  typeOrSelect: 'Type or Select',
  lastCheck: 'Last Check',
  checkedBy: 'Checked By',
  finished: 'Finished',
  proofs: 'Proofs',
  retry: 'Try Again',
  retrying: 'Loading',
  retryOnError: 'Error - tap to retry',
  openToRetry: 'loading failed. Retry',
  loading: 'Loading',
  userDeleted: 'User Deleted',
  groupDeleted: 'Group Deleted',
  templatesUpdated: 'Templates Updated',
  templateCreated: 'Template created',
  templateUpdated: 'Template updated',
  nameIsRequired: 'Name is required',
  extendRejectDescription:
    'If you want to reject the extension please provide a reason.',
  myRole: 'My Role',
  home: 'Home',
  goBack: 'Go back',
  uncaughtError:
    'Sorry, something unexpected happened. The error has been automatically reported to us.',
  firstNameRequired: 'First name is a required field',
  lastNameRequired: 'Last name is a required field',
  phoneRequired: 'Phone is a required field',
  profileSaved: 'Profile saved',
  noParticipantsFound: 'No participants found',
  totalPermits: 'Total Permits (all time)',
  totalChecklists: 'Total Checklists (all time)',
  lastTwoWeeks: 'Last 2 weeks',
  seeMore: 'See more',
  viewAll: 'View All',
  viewPermitTimeline: 'View Permit Timeline',
  viewPermits: 'View Permits',
  viewPlan: 'View Plan',
  editPlan: 'Edit Plan',
  generatePermitPDF: 'Generate Permit PDF',
  generateChecklistPdf: 'Download Checklist PDF',
  generatePermitPDFDescription: 'Create a PDF export of the Permit',
  generateChecklistPdfDescription: 'Create a PDF export of the Checklist',
  generating: 'Generating...',
  copiedToClipBoard: 'Copied to Clipboard!',
  copyToClipboard: 'Copy to Clipboard',
  linkExpiry: 'Link expires in 60 minutes',
  permitCancelled: 'Permit Cancelled',
  projectOverview: 'Project Overview',
  organisationOverview: 'Organisation Overview',
  overview: 'Overview',
  pleaseDownloadApp: 'Please download the mobile app',
  autoRefreshEveryMinute: 'Auto Refresh (every minute)',
  requireAnnotations: 'Require annotations',
  requireApproval: 'Require approval',
  requireBriefingVerification: 'Do briefings require photo verification?',
  requireGeolocationForBriefings: 'Do briefings require geolocation?',
  requireGeolocationForPermit: 'Do permits require geolocation?',
  requireGeolocationForPostPermitChecks:
    'Do post permit checks require geolocation?',
  geoLocationInputType: 'What is the geolocation input type?',
  dateRange: 'Date Range',
  selectDates: 'Select Dates',
  restart: 'Restart',
  typeHere: 'Type here...',
  propertyName: 'Property Name',
  selectBetween: 'Please select between {min} and {max} options',
  selectOptions: (minOptions) => `Please select ${minOptions} option(s)`,
  selectOrTypeInTemplate: 'Select or Type in Template',
  apply: 'Apply',
  selectStartDate: 'Select Start Date',
  SelectAssignee: 'Select assignee',
  selectEndDate: 'Select End Date',
  projectViewerTooltip:
    'Can see all the permits in the project but cannot create or approve permits.',
  projectAdminTooltip:
    'Has full access to both web and mobile app including ability to issue, reject, extend and close permits. Project Admins can invite new users to SiteAssist and edit their roles.',
  supervisorTooltip:
    'Can request an individual permit to work for themselves but also request and submit a permit to work covering multiple Operatives. Can view all permits related to them.',
  operativeTooltip:
    'Can request an individual permit using the mobile application as well as view all open permits related to them.',
  permitApproverTooltip:
    'Can create an approver permits based on their Authorisation Groups',
  projectUserTooltip:
    'Can request a Permit to Work for themselves but also request and submit a permit to work covering multiple Users.',
  fromCameraOrGallery: 'from camera or gallery',
  onlyFromCamera: 'only from camera',
  requiresSignature: 'Requires signature',
  requireProofFromCamera: 'Require proof to be taken with camera',
  requireProofFromCameraNote:
    'Requires proof from the camera, please save a draft and upload the proof on mobile.',
  requireProofFromCameraDescription:
    'Specifies whether a proof has to be a picture taken with the camera or is allowed to be from the gallery.',
  done: 'Done',
  checklistCompleted: 'Checklist Completed',
  documents: 'Documents',
  role: 'Role',
  userType: 'User Type',
  rootRole: 'Root Role',
  user: 'User',
  userSelect: 'User Select',
  userInvolved: 'User Involved',
  newUser: 'New User',
  previouslyAdded: 'Previously Added',
  superAdmin: 'Super Admin',
  supportUser: 'Support User',
  permitApprover: 'Permit Approver',
  lastActive: 'Last active',
  roleAccountViewerTooltip:
    'Can see all data in an organisation. Actions they can perform are controlled by project membership',
  roleAccountOwnerTooltip:
    'Have access to all Organisation and Project settings. Can create templates to be used by projects as well as editing and viewing all projects.',
  roleUserTooltip:
    'A User is invited to your account and can have multiple roles depending on permission level on a project level. Can be Project Admin, Permit Approver, User, or Project Viewer in projects.',
  resendInvite: 'Resend invite',
  never: 'Never',
  accountOwner: 'Account Owner',
  accountViewer: 'Account Viewer',
  inviteSent: 'Invite sent',
  teamsAndRoles: 'Team and Roles',
  inviteUserToProject: 'Invite User to Project',
  inviteUser: 'Invite User',
  createGroup: 'Create Group',
  create: 'Create',
  thisProjectHasNoGroups: 'This project has no groups',
  thisGroupHasNoMembers: 'This group has no members',
  thisGroupHasNoPermits: 'This group has no permits',
  userRole: 'User Role',
  userRoleUpdated: 'User role updated',
  userRoleNotUpdated: 'User role could not be updated',
  projectAdmin: 'Project Admin',
  supervisor: 'Supervisor',
  operative: 'Operative',
  authorisation: 'Authorisation',
  authorisedPerson: 'Authorised Person',
  authorisedPermits: 'Authorised Permits',
  authorisedPermitsToolTip:
    'People in this group will be able to Issue these Permits',
  people: 'People',
  publishedDescription:
    'Making this template live will allow this template to be used in permit requests.',
  enabled: 'Enabled',
  permit: 'Permit',
  permitChecklists: 'Permit Checklists',
  search: 'Search',
  users: 'Users',
  groups: 'Groups',
  groupName: 'Group Name',
  templates: 'Templates',
  noSearchResults: 'No results found',
  dynamicNoSearchResults: (searchedItem: string) =>
    `Could not find any ${searchedItem} against the search query`,
  organisations: 'Organisations',
  organisationName: 'Organisation Name',
  testOrganisations: 'Test Organisations',
  enterBasicGroupInfo: 'Enter some basic information about the group',
  addGroupMembersFromProject:
    'Add the members of this group from your project team',
  groupDetails: 'Group Details',
  createAuthGroup: 'Create Authorisation Group',
  members: 'Members',
  membersUpdated: 'Members Updated',
  groupCreated: 'Group Created',
  fullScreen: 'Full Screen',
  minimise: 'Minimise',
  permitActions: 'Permit Actions',
  checklistActions: 'Checklist Actions',
  permitWorkflow: 'Permit workflow',
  permitWorkflowDescription:
    'Configure the optional stages that this permit moves through during its lifecycle.',
  permitWorkflowRequest: 'Request',
  permitWorkflowRequestDescription:
    'The Permit Holder has to answer configured questions in order to Request a Permit. Project Admins review the answers to questions and decide whether to issue or reject the Permit.',
  permitWorkflowPostPermitChecks: 'Post Permit Checks',
  permitWorkflowPostPermitChecksDescription:
    'The Permit Holder has to answer configured questions after work is complete.',
  permitWorkflowFinalSignOff: 'Final Sign-Off',
  permitWorkflowFinalSignOffDescription:
    'This permit requires final sign-off to be closed',
  permitWorkflowFinalSignOffComplete: 'Complete Final Sign-Off',
  permitHolderQuestions: 'Permit Holder Questions',
  approverQuestions: 'Approver Questions',
  authorisedPersonQuestions: 'Authorised Person Questions',
  authorisedPersonTooltip:
    'The Authorised Person or Project Admin will start the process and issue the permit to a Permit Holder.',
  permitHolderTooltip:
    'The Permit Holder will request a permit, and it will be issued after the Permit Approver or Project Admin reviews and approves the request.',
  permitRequiresApproval:
    'Before the permit can be opened, it will require approval by someone authorised',
  permitDoesntRequireApproval:
    'The permit will be automatically issued after the permit holder creates or receives it',
  searchByNameOrEmail: 'Search by Name or Email',
  submitted: 'Submitted',
  submittedBy: 'Submitted By',
  noAuthorisedPersonsFound:
    '<p>No Permit Approvers found in this project. Try <a class="text-link">adding Permit Approvers</a> to your project, before adding them to a group</p>',
  noPermitsFound:
    '<p>No permits found for this project. Try <a class="text-link">adding Permit Templates</a> to your project, before adding them to a group</p>',
  noVersionsFound: 'No versions found',
  noTemplatesFound: 'No templates found',
  noChecklistsFound: 'No checklists found',
  noDraftsFound: 'No drafts found',
  memberRemoved: 'Member removed',
  memberAdded: 'Member added',
  pleaseCompleteAllQuestions: 'Please complete all questions before proceeding',
  sentToPermitHolder: 'Sent to Permit Holder',
  permitReceived: 'Permit Received',
  answersNotSubmittedYet: 'Answers not submitted yet',
  receivePermit: 'Receive Permit',
  reviewPermitIssue:
    'Please review the information provided by the issuer of the permit.',
  reviewPermitInfo: 'Please review the information in the Permit.',
  review: 'Review',
  reviewQuestionForPermit: 'Review the questions for the permit',
  reviewQuestionForChecklist: 'Review the questions for the checklist',
  general: 'General',
  authorisationGroups: 'Authorisation Groups',
  authorisationGroup: 'Authorisation Group',
  team: 'Team',
  noPermitTypesAvailable: '“No permit types available',
  noUsersFound: 'No users found',
  permitSigned: 'Permit Signed',
  workflow: 'Workflow',
  initiatorRole: 'Initiator Role',
  initiatorRoleQuestion: 'Who starts the process?',
  allowExtensionBeyondMaxDuration: 'Allow extension beyond max duration',
  requiresFinalSignOffQuestion:
    'Does the Permit require Final Signoff to be Closed?',
  allowApprovalDrafts: 'Allow Approval Drafts',
  skipPostPermitChecksQuestion:
    'Does the Permit Holder need to do Post Permit Checks?',
  yes: 'Yes',
  no: 'No',
  acknowledgeDisclaimer: 'Please check this box to acknowledge the disclaimer',
  templateOnlyViewWarningTitle:
    'Templates can only be edited at the Organisation level by an Account Owner',
  templateOnlyViewWarningDescription:
    'If you think there should be changes made to this template please speak to:',
  templateOnlyViewWarningTitleOwner:
    'Templates can only be edited at the Organisation level',
  templateOnlyViewWarningDescriptionOwner:
    'Please go to the organisation level to edit it: ',
  templateRemovedFromProject: 'Template removed from Project',
  templateAddedToProject: 'Template added to Project',
  permitMaximumDuration: 'Permit Maximum Duration',
  enablePermitMaximumDuration: 'Enable Permit Maximum Duration',
  valueMustBePositive: 'Value must be positive',
  requiresDocument: 'Document Required',
  required: 'Required',
  notRequired: 'Not Required',
  documentProof: 'Document Proof',
  maxFileSizeExceeded: 'File size is above the limit of {0}mb',
  ensureMaxDuration:
    'Please create permits at least 30 minutes into the future from the start time',
  theFollowingPropertiesWillBeCalculated:
    'The following properties will be calculated',
  conditionalQuestionGroup: 'Conditional Question Group',
  conditionalQuestion: 'Conditional Question',
  showGroupIfAnswer: 'Show Group if answer is:',
  hideGroupIfAnswer: 'Hide Group if answer is:',
  conditionalQuestionGroupTip:
    'The permit requester will skip this question group, depending on their answer',
  fetchingProjectUser: 'fetching user details...',
  conditionalQuestionGroupExplanation:
    'If answering "{0}", you will proceed to Question {1}',
  versions: 'Versions',
  createNewVersion: 'Create New Version',
  permitTemplateVersions: 'Permit Template Versions',
  currentVersion: 'Current Version',
  baseTemplate: 'Base Template',
  createdBy: 'Created by',
  updatedBy: 'Updated by',
  activatedBy: 'Activated by',
  draftVersionCreated: 'Draft Version Created',
  draftCreated: 'Draft Created',
  fetchingOrganisationUser: 'fetching user details...',
  userDetails: 'User Details',
  userCreated: 'User Created',
  updateUser: 'Update User',
  userUpdated: 'User Updated',
  userUpdateFailed: 'User Update Failed',
  templateVersionUpdated: 'Template Version Updated',
  activateVersionText:
    'Making this active will make it the current permit template version.',
  activateVersion: 'Activate Version',
  activateActionMessage: 'You must finish editing to activate this action',
  deleteVersion: 'Delete Version',
  versionActivated: 'Version activated',
  versionDeleted: 'Version Deleted',
  activate: 'Activate',
  active: 'Active',
  deactivate: 'Deactivate',
  deactivated: 'Deactivated',
  showIfAnswerIs: 'Show if answer is',
  hideIfAnswerIs: 'Hide if answer is',
  detailsAndUsers: 'Details and Users',
  confirmActivateTemplateVersion:
    'Are you sure you want to activate this version? This means all future permits will use this version.',
  confirmActivateChecklistTemplateVersion:
    'Are you sure you want to activate this version? This means all future checklists will use this version.',
  confirmDeleteTemplateVersion:
    'Are you sure you want to delete this version? This action can’t be undone.',
  sendForgotPasswordLink: 'Send Forgot Password Link',
  forgotPasswordLinkSent: 'Forget Password Link Sent',
  newLocation: 'New Location',
  newPermit: 'New Permit',
  newTemplate: 'New Template',
  newChecklist: 'New Checklist',
  new: 'New',
  confirmUnsavedChanges:
    'You have made some changes to the version. You must save the version before you can Activate it',
  confirmLeavePage:
    'You have unsaved changes. Are you sure you want to leave this page?',
  confirmInitiatorRoleChange:
    'Are you sure you want to change the initiator role? This might cause some data loss.',
  confirmLeavePageSaveDraft:
    'You have unsaved changes. Are you sure you want to leave this page and discard this draft?',
  tooltipRequested:
    'Permits requested on Site. Must be reviewed by an authorised user.',
  tooltipOpen: 'Permits that are currently Open',
  tooltipIssued: 'Permits that are currently in a Pre-Start state',
  tooltipExtensionRequested: 'Permits that have an extension request',
  tooltipExpired:
    'Expired permits. Permit Holder must declare that work is complete or be Revoked by an authorised user.',
  tooltipPostPermitChecks:
    'Work has been declared complete and the Permit Holder must complete checks or wait for a countdown (fire watch for example)',
  tooltipFinalSignoff:
    'Works and potential post permit checks are completed. An authorised user must and review and sign off to close the permit.',
  groupSkipped: '(Group Skipped)',
  viewActions: 'View Actions',
  requestChanges: 'Request Changes',
  requestChangesDescription:
    'Please provide details as to why the permit request cannot be granted at this time',
  respondToChangesDescription:
    'These are the changes that have been requested.',
  enterRequestChanges:
    'E.g. Please provide clearer image of the signage for question 1',
  permitChangesRequested: 'Permit changes are requested',
  saveDraftDescription:
    'Write a note so that you can find the draft easily later',
  changesDone: 'Changes Done',
  changesRequested: 'Changes Requested',
  requestedChange: 'requested a change to this permit request.',
  requestedBy: 'Requested By',
  respond: 'Respond',
  respondToChanges: 'Respond to Changes',
  addressChanges: 'Address Changes',
  changesAddressed: 'Changes Addressed',
  addressedChange: 'addressed a change to this permit',
  addExistingSiteAssistUser: 'Existing SiteAssist User',
  permissions: 'Permissions',
  generalPermissions: 'General Permissions',
  projectPermissions: 'Project Permissions',
  removePermission: 'Remove Permission',
  editPermission: 'Edit Permission',
  viewTemplateSettings: 'View Template Settings',
  readMore: 'Read More',
  addUserWithoutSaAccount: 'Person Without SiteAssist Account',
  addExternalUser: 'Add External User',
  actions: 'Actions',
  changeRole: 'Change Role',
  disableUser: 'Disable User',
  viewProfile: 'View Profile',
  none: 'None',
  externalUser: 'External User',
  createNewPermit: 'Create a new permit',
  createNewChecklist: 'Create a new checklist',
  issueNewPermit: 'Issue a new permit',
  submitPeriodicCheck: 'Submit Periodic Check',
  viewAndDoPeriodicCheck: 'View all periodic checks and do a new check',
  permitDraftSaved: 'Permit Draft Saved',
  checklistDraftSaved: 'Checklist Draft Saved',
  permitDraftUpdated: 'Permit Draft Updated',
  checklistDraftUpdated: 'Checklist Draft Updated',
  note: 'Note',
  reasonForUpdateDescription:
    'You can leave a note to describe the changes you made',
  draftName: 'Draft Name',
  draftNote: 'Draft Note',
  draftNoteTitle:
    'You can add a note to this draft permit request to make it easier to identify.',
  issueDescription: 'Issue the permit to begin work on site',
  rejectDescription: 'Reject the Permit Request',
  requestMessage: 'Request Message',
  archive: 'Archive',
  archived: 'Archived',
  test: 'Test',
  archivedLocation: 'Archived Location',
  publishedPlan: 'Published Plan',
  unpublishedPlan: 'Unpublished Plan',
  archivedPlan: 'Archived Plan',
  unarchive: 'Unarchive',
  projectStatus: 'Project Status',
  projectSettings: 'Project Settings',
  addTemplatesToProject: 'Add Templates To Project',
  addChecklistsToProject: 'Add Checklists To Project',
  addOrganisationLink: 'Add Link to Organisation',
  noMoreTemplates: 'No more templates available',
  confirmDeleteTemplate:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0}</span> from the project?',
  confirmDeleteOrganisationLink: 'Are you sure you want to delete this link?',
  notifications: 'Notifications',
  read: 'Read',
  unread: 'Unread',
  readAndUnread: 'Read and unread',
  unreadOnly: 'Unread only',
  markAllAsRead: 'Mark all as read',
  noNotifications: 'No notifications',
  noUnreadNotifications: 'No unread notifications',
  businessUnits: 'Business Units',
  businessUnit: 'Business Unit',
  businessUnitCreated: 'Business Unit Created',
  businessUnitUpdated: 'Business Unit Updated',
  businessUnitDeleted: 'Business Unit Deleted',
  businessUnitOverview: 'Overview',
  permitHolderFilter: 'Permit Holder',
  permitApproverFilter: 'Permit Approver',
  settings: 'Settings',
  properties: 'Properties',
  property: 'Property',
  propertyComponents: 'Property Components',
  questionComponentsReadOnlyDescription:
    'This template version is active. Create a new version to make changes.',
  globalSettings: 'Global Settings',
  publish: 'Publish',
  proofUploadFailed: 'One or more proofs failed to upload',
  confirmDeleteProjectFromBusUnit:
    'Are you sure you want to delete this project from the business unit?',
  createBusinessUnit: 'Create Business Unit',
  continue: 'Continue',
  pleasePassDeviceTo: 'Please pass the device to:',
  addProject: 'Add Project',
  optional: 'Optional',
  optionalField: 'Optional Field',
  noParticipantTimelineEntries: 'Nothing to show for this participant',
  briefParticipant: 'Brief Participant',
  contactParticipant: 'Contact Participant',
  attachPDF: 'Attach PDF',
  addParticipantsAndInformation: 'Add Participants & Information',
  addParticipantsAndInformationDescription:
    'Please add the participants below that you would like to brief. You may be required to capture your current location, and/or add a photo to continue.',
  clickThePlusButtonTo: (action: string) =>
    `Click the plus button to ${action}`,
  addAttachment: 'add an attachment',
  captureLocationAction: 'capture a location',
  takePhotoAction: 'take a photo',
  addParticipantsAction: 'add participants',
  permitPdfs: 'Permit Documents',
  acknowledgeDocument: 'Acknowledge Document',
  acknowledge: 'Acknowledge',
  acknowledged: 'Acknowledged',
  documentAcknowledged: 'Document Acknowledged',
  requiresAdditionalInput: 'Requires Input',
  transferPermit: 'Transfer Permit',
  reasonForTransfer: 'Reason for Transfer',
  reasonForTransferDots: 'Reason for Transfer...',
  transferPermitDescription:
    'Transfer the ownership of this permit to a SiteAssist user.',
  transferTo: 'Transfer To',
  transferingTo: 'Transfering To',
  transferFrom: 'Transfer From',
  transferRequestMade:
    'A request has been made to transfer the ownership of this permit to',
  transferRequested: 'Transfer Requested',
  transferRequest: 'Transfer Request',
  accept: 'Accept',
  transferSent: 'Transfer Sent',
  transferRejected: 'Transfer Rejected',
  transferInitiated: 'Transfer Initiated',
  transferInitiatedBy: 'Transfer Initiated By',
  transferCanceled: 'Transfer Canceled',
  transferAwaitingRecipient: 'Transfer Awaiting Recipient',
  transferAccepted: 'Transfer Accepted',
  transferAwaitingApproval: 'Transfer Awaiting Approval',
  enablePermitTransfer: 'Enable permit holder transfer',
  permitHolderSelfApprove: 'Permit holder self approve',
  transferReason: 'Transfer Reason',
  cancelTransfer: 'Cancel Transfer',
  rejectTransfer: 'Reject Transfer',
  reasonForRejection: 'Reason for Rejection',
  lastBriefed: 'Last Briefed',
  mainContact: 'Main Contact',
  otherParticipants: 'Other Participants',
  noOtherParticipants: 'No other participants',
  organisationTemplate: 'Organisation Template',
  pdf: 'Pdf',
  operator: 'Operator',
  projectAdditions: 'Project Additions',
  removeTransferUser: 'Remove Transfer User',
  selectTransferUser: 'Select Transfer User',
  selectTemplate: 'Select Template',
  initiateTransfer: 'Initiate Transfer',
  importViaCsv: 'Import via CSV',
  downloadCsvTemplate: 'Download CSV Template',
  csvImportTutorial: 'CSV Import Tutorial',
  uploadCsvStepsDescription: 'To upload an CSV file, please follow these steps',
  uploadCsvStep1: '1. Download the CSV Template',
  uploadCsvStep2: '2. Populate template with asset data',
  uploadCsvStep3: '3. Save your file as a .CSV and upload',
  important: 'Important!',
  uploadCsvImportant1: 'Use the provided template to enter asset data.',
  uploadCsvImportant2: "Don't add new columns or edit column headers.",
  csvTemplateDownloaded: 'CSV Template Downloaded',
  confirmDeleteText:
    "Are you sure you want to <span class='text-red text-bold'>delete</span> this question?",
  confirmAcknowledgeText: 'You must acknowledge this document to proceed.',
  permitTransferRejected: 'Permit Transfer Rejected',
  permitTransferInitiated: 'Permit Transfer Initiated',
  permitTransferCancelled: 'Permit Transfer Cancelled',
  permitTransferAccepted: 'Permit Transfer Accepted',
  participantRemoved: 'Participant Removed',
  participantInformation: 'Participant Information',
  briefingLog: 'Briefing Log',
  createPasswordWelcomeText:
    'Welcome to SiteAssist, please create your account password',
  createPasswordInfoText: 'You need to create a password before you can login.',
  ssoLoginNotAvailable:
    'SSO login is not available. Please log in with another user',
  uploadPdf: 'Upload PDF',
  calculateDurationBasedOnDays: 'Calculate duration based on days',
  calculatedByFormula: 'Calculated by formula',
  saveAnnotations: 'Save Annotations',
  setLocation: 'Set Location',
  ownedByAnotherOrg: 'Owned by another organisation',
  ownedByOrg: (orgName: string) => `Owned by ${orgName}`,
  addAnotherUser: 'Add Another User',
  participantAdded: 'Participant added',
  conditionalLogic: 'Conditional Logic',
  conditionalLogicDescription:
    'Create rules to define the visibility of the questions in this template.',
  approvalConditionalLogicDescription:
    'Set the conditions that will trigger this approval.',
  addRule: 'Add Rule',
  deleteRule: 'Delete Rule',
  addCondition: 'Add Condition',
  impersonateUser: 'Impersonate User',
  unimpersonateUser: 'Unimpersonate User',
  ifAnswer: 'If answer',
  thenShow: 'Then show',
  noRuleText: 'There is no rules associated with this question.',
  approvalNoRuleText: 'There is no rules associated with this approval.',
  selectQuestionPlaceholderText: 'Select Question / Group',
  youCannotPerformThisAction: 'You cannot perform this action',
  preventDeleteQuestionWithRuleText:
    'This question has rule(s) associated with it. Please remove it from all rules before you proceed.',
  generalApproval: 'General Approval',
  approval: 'Approval',
  doesNotRequireApprovalDescription:
    'Does not require Approval. If you want to require approval please go to the settings tab.',
  approvalOrder: 'Approval Order',
  orderChanged: 'Order Changed',
  approvalOrderText:
    'Is there any order that you would like the approval to take place?',
  insertLabel: 'Insert Label',
  initiatorRoleText:
    'If your template needs questions to be reviewed by an auth group before, please select.',
  extensionsReviewTitle: 'Who can review Extensions?',
  finalSignoffSubtitle:
    'If your template needs questions to be reviewed by an auth group before, please select.',
  approvePermitTransfersTitle: 'Who can approve permit transfers?',
  resumeAfterSuspensionTitle: 'Who can resume after suspension?',
  approvals: 'Approvals',
  setConditions: 'Set Conditions',
  editConditions: 'Edit Conditions',
  viewConditions: 'View Conditions',
  noProofsProvided: 'No proofs provided',
  confirmLocation: 'Confirm Location',
  checkin: 'Check In',
  locationCheckIn: 'Location Check In',
  locationLoading: 'Location Loading ...',
  folderPermissionsMissing: 'Required file access permissions missing',
  nominateAnApprover: 'Nominate an Approver',
  nominateAnApproverText:
    'If you would like to nominate a specific approver, select from the dropdown below. Otherwise, click Skip.',
  for: 'For',
  nextNominatedApprover: 'Next Nominated Approver',
  by: 'By',
  to: 'to',
  addFilter: 'Add Filter',
  removeFilter: 'Remove Filter',
  discardExtensionRequest: 'Discard Extension Request',
  extensionRequestedDetails: 'Extension Request Details',
  extensionRequestDisclaimer:
    'By signing this you acknowledge your responsibility as the current Permit Holder and agree to approved the extension request.',
  permitList: 'Permit List',
  checklistList: 'Checklist List',
  draftDeleted: 'Draft Deleted',
  noUserFound: 'No user found',
  errorPleaseTryAgain: 'Error (please try again)',
  webBrowser: 'Web Browser',
  ios: 'IOS',
  android: 'Android',
  unknown: 'Unknown',
  projectAdded: 'Project Added',
  projectRemoved: 'Project Removed',
  appUpdatedSuccessfully: 'App Updated Successfully',
  tapToCaptureSignature: 'Tap to capture signature',
  noEntitiesFound: (entityType: string) => `No ${entityType}s were found`,
  actionType: 'Action Type',
  taskName: 'Task Name',
  permitRole: 'Permit Role',
  newTask: 'New Task',
  preStartTaskDescription: (readOnly: boolean) =>
    `${
      readOnly ? 'Tasks' : 'Set the tasks'
    } that must be completed for the Permit to start`,
  preStartTaskSubDescription:
    'The produce operates based on the order of the list below.',
  noPreStartTasksFound: 'You don’t have any tasks configured.',
  editTask: 'Edit Task',
  viewTask: 'View Task',
  deleteTask: 'Delete Task',
  deleteTaskConfirmation: (taskName: string) =>
    `Are you sure you want to delete ${` "${taskName}"` || 'this task'}?`,
  taskDeletedSuccessfully: 'Task deleted successfully',
  taskMovedSuccessfully: 'Task moved successfully',
  moveUp: 'Move Up',
  moveDown: 'Move Down',
  createNewTask: 'Create New Task',
  taskCreatedSuccessfully: 'Task created successfully',
  taskUpdatedSuccessfully: 'Task updated successfully',
  briefTask: 'Brief Task',
  submitChecklist: 'Submit Checklist',
  checklistSubmitted: 'Checklist Submitted',
  submitRelevantChecksForPermit: 'Submit relevant checks for this permit',
  assignTo: 'Assigned To',
  permitHolderByDefault: 'Permit Holder (by default)',
  completeTask: 'Complete Task',
  configureTemplateSettingsText: 'Configure the settings for your template.',
  propertyUpdatedSuccessfully: 'Property updated successfully',
  propertyDeletedSuccessfully: 'Property deleted successfully',
  configureTemplateSettings: 'Configure the settings for your template.',
  mustBeCompletedOnMobile: 'Must be completed on mobile device.',
  noTasksFound: 'There are no outstanding tasks',
  noOrganisationDescription:
    'You are not part of any organisations. This could be because you have been removed by an administrator. Please speak to us if you think a mistake was made.',
  tableOfContents: 'Table of Contents',
  welcomeBack: (name: string) => `Welcome back${name ? `, ${name}` : ''}!`,
  backToOrganisation: 'Back to Organisation',
  backToProject: 'Back to Project',
  pdfViewer: 'PDF Viewer',
  pdfEditor: 'PDF Editor',
  remaining: 'Remaining',
  periodicCheckSubmitted: 'Periodic Check Submitted',
  extensionApproved: 'Extension Approved',
  extensionRejected: 'Extension Rejected',
  enablePermitSuspension: 'Enable permit suspension',
  enablePermitShorten: 'Enable shorten permit',
  enablePermitRequestExtension: 'Enable permit request extension',
  suspended: 'Suspended',
  shortened: 'Shortened',
  nTasksOutstanding: (n: number) =>
    `${n} Task${n === 1 ? '' : 's'} Outstanding`,
  tasks: 'Tasks',
  numTasks: (n: number) => `${n} Task${n === 1 ? '' : 's'}`,
  numChecklists: (n: number) => `${n} Checklist${n === 1 ? '' : 's'}`,
  numLinkedDocuments: (n: number) =>
    `${n} Linked Document${n === 1 ? '' : 's'}`,
  typeDeleteToConfirm: 'Type DELETE to confirm',
  groupCreatedSuccessfully: 'Group created successfully',
  multipleSelected: 'Multiple selected',
  showPermits: 'Show Permits',
  showChecklists: 'Show Checklists',
  showArchived: 'Show Archived',
  exitWithoutSaving: 'Exit without saving?',
  ifYouExitWithoutSaving:
    'If you exit without saving you will lose your zones. Save now?',
  saveNow: 'Yes, save now',
  noExit: 'No, exit',
  zoneSelected: (selectedZones: number) =>
    `${selectedZones > 0 ? selectedZones : 'No'} ${
      selectedZones > 1 ? 'Zones' : 'Zone'
    } Selected`,
  confirmToContinueRaisingAPermit: 'Confirm to continue raising permit',
  genericProjectPlanError:
    'Something went wrong while initalizing the project plan',
  showZones: 'Show Zones',
  suspendPermit: 'Suspend Permit',
  suspendPermitDescription: 'Suspend this Permit',
  calibrate: 'Calibrate',
  calibrateMapInstructions:
    "Move the map and press calibrate when you're happy with the position",
  suspendPermitReason: 'Reason for suspension',
  resumePermit: 'Resume Permit',
  resumePermitRequest: 'Resume Permit Request',
  shortenPermit: 'Shorten Permit',
  resumePermitDescription: 'Resume this Permit',
  resumePermitReason: 'Reason for resuming',
  permitResumed: 'Permit Resumed',
  permitSuspended: 'Permit Suspended',
  suspensionReason: 'Suspension Reason',
  resumed: 'Resumed',
  noReasonProvided: 'No reason provided',
  suspendPermitDeclaration:
    'By signing this you acknowledge that this permit will be suspended, and all activities in the work area must be stopped until the permit is resumed.',
  resumePermitDeclaration:
    'By signing this you acknowledge that the permit will be resumed, and activities can recommence in the work area.',
  rejectResumePermitDeclaration:
    'By signing this you acknowledge your responsibility as the current Permit Holder and agree to suspend the permit.',
  resumePermitRequested: 'Resume Permit Requested',
  resumeRequested: 'Resume Requested',
  resumeRequestApproved: 'Resume Request Approved',
  resumeRequestRejected: 'Resume Request Rejected',
  reasonForResume: 'Reason for Resume',
  rejectResumeDeclaration:
    'If you want to reject the extension please provide a reason.',
  reason: 'Reason',
  resumeRequestReason: 'Resume Request Reason',
  reviewResumeRequest: 'Review Resume Request',
  reviewResumeRequestDeclaration:
    'By approving this request, you acknowledge that the permit will be resumed, and all work activities may recommence. By rejecting this request, you acknowledge that the permit will remain suspended, and work activities must not recommence.',
  rotateScreen: 'Rotate Screen',
  bestUserExperienceProjectPlan:
    'For the best experience view this plan in horizontal view',
  permitClonable: 'Permit Clonable',
  checklistClonable: 'Checklist Clonable',
  checklistCreator: 'Checklist Creator',
  noBusinessUnitsFound: 'Could not find any business units',
  editBusinessUnit: 'Edit Business Unit',
  nUnsyncedItems: (n: number) => `${n} Unsynced Item${n === 1 ? '' : 's'}`,
  syncNow: 'Sync Now',
  synced: 'Synced',
  deleteMutationAlertTitle: 'Clear unsynced item',
  deleteMutationAlertMessage:
    'This will delete this item permanently. Would you like to continue',
  syncAll: 'Sync All',
  selectPermit: 'Select Permit',
  linkedDocuments: 'Linked Documents',
  viewPermit: 'View Permit',
  viewChecklist: 'View Checklist',
  dateRaised: 'Date Raised',
  checklistAuthor: 'Checklist Author',
  addDocuments: 'Add Documents',
  addLink: 'Add Link',
  selectDocument: 'Select Document',
  searchByNameOrId: 'Search by Name or ID',
  returnToMainLinkedProcess: 'Return to Main Document',
  viewMore: 'View More',
  viewInformation: 'View Information',
  suspendedBy: 'Suspended By',
  userInformation: 'User Information',
  myProjects: 'My Projects',
  contactNumber: 'Contact Number',
  currentUser: 'Current User',
  isChecklistOwnedByRegister: 'Is checklist owned by a register item?',
  myChecklists: 'My Checklists',
  AllChecklists: 'All Checklists',
  configuration: 'Configuration',
  beforePermitHolder: 'Before Permit Holder',
  afterPermitHolder: 'After Permit Holder',
  requireSubmissionText: 'Require submission text',
  range: (min = 1, max = 1) => `Range: Max ${max} and Min ${min}`,
  selectRegisterItem: 'Select Register Item',
  confirmDeleteQuestion: 'Are you sure you want to delete this question?',
  networkError: 'Network error. Please check your connection and try again.',
  numberOfRules: (n: number) =>
    `Conditional Logic: ${n} Rule${n === 1 ? '' : 's'}`,
  answerUsedByRule: 'Used by Conditional Logic',
  timezone: 'Timezone',
  setTimezone: 'Set Timezone',
  uploadLogo: 'Upload Logo',
  logoOptional: 'Logo (Optional)',
  stage: 'Stage',
  views: 'Views',
  editViewName: 'Edit View Name',
  viewName: 'View Name',
  viewCreated: 'View Created',
  viewUpdated: 'View Updated',
  youDontHaveAnyCustomViewsYet: `You don't have any custom views yet.`,
  createNewView: 'Create New View',
  newView: 'New View',
  deleteView: 'Delete View',
  viewDeleted: 'View Deleted',
  quickFilters: 'Quick Filters',
  notPinned: 'Not Pinned',
  padToNumberOfCharacters: 'Pad to number of characters',
  pinOnTheLeft: 'Pin on the left',
  pinOnTheRight: 'Pin on the right',
  pinItem: 'Pin Item',
  override: 'Override',
  overrideFormulaValue: 'Override Formula Value',
  overrideRegisterItemFormulaWarning:
    'This will override the value calculated by this formula and LOCK it to the value you set.',
  removeRegisterItemFormulaOverrideWarning:
    'This will remove the override and allow the formula to be calculated according to configuration.',
  noAvailablePropertyComponents: 'No available property components',
  toastTesting: 'Toast testing',
  selectView: 'Select View',
  defaultView: 'Default View',
  selectFilterCondition: 'Select Filter Condition',
  items: 'Items',
  itemPermissions: 'Item Permissions',
  registerItemPermissionsDescription:
    'Select which authorisation groups are allowed to add or update items',
  CSVImport: 'CSV Import',
  addItems: 'Add Items',
  assignToUserProperty: 'Assign to User Property',
  updateItems: 'Update Items',
  filters: 'Filters',
  newFilter: 'New Filter',
  noAvailableActions: 'No available actions',
  answerHere: 'Answer here',
  contains: 'Contains',
  any: 'Any',
  isPresent: 'Is Present',
  isNotPresent: 'Is Not Present',
  defaultFilter: 'Default Filter',
  noFilter: 'No Filter',
  selectFilter: 'Select Filter',
  offlineActionsSynced: 'All offline actions synced',
  projectLocation: 'Project Location',
  doChecklistsRequireGeolocation: 'Do Checklists Require Geolocation?',
  applyLocationSettingsForThisChecklistTemplate:
    'Apply location settings for this Checklist Template',
  switchUser: 'Switch User',
  loggedInAs: 'Logged in as',
  validations: 'Validations',
  errorMessage: 'Error Message',
  newValidation: 'New Validation',
  deleteValidationMessage: 'Are you sure you want to delete this validation?',
  deleteValidation: 'Delete Validation',
  pasteOptions: 'Paste Multiple Choice Options',
  pasteOptionsDescription:
    'Paste your options in the field below. Each option should be on a new line.',
  setAnswers: (answers: string[]) =>
    `Set ${answers?.length || ''} answer${answers?.length > 1 ? 's' : ''}`,
  pasteOptionsPlaceholder: 'Paste options here',
  pasteAnswers: 'Paste answers',
  checkinPrompt:
    'Please use the button below to indicate your location on the map',
  showOnMap: 'Show on map',
  completeAtPointOfWorks:
    'You must complete this document at the point of works using a mobile device',
  image: 'Image',
  deleteApprovalMessage: 'Are you sure you want to delete this approval?',
  newApproval: 'New Approval',
  editApproval: 'Edit Approval',
  editQuestions: 'Edit Questions',
  openPermit: 'Open Permit',
  openChecklist: 'Open Checklist',
  selectPermitToView: 'Select a permit to preview it',
  selectChecklistToView: 'Select a checklist to preview it',
  startDateMustBeInTheFuture: 'Start date must be in the future',
  shortenPermitDescription: 'Shorten the Permit duration',
  permitShortened: 'Permit duration successfully shortened',
  receiver: 'Receiver',
  newFinalSignoff: 'New Final Signoff',
  editFinalSignoff: 'Edit Final Signoff',
  finalSignOffLabel: 'Final SignOff Label',
  newEndTimeFrom: 'New end time from:',
  requesterConfirmationText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  finalSignOffQuestions: 'Final SignOff Questions',
  finalSignOffs: 'Final SignOffs',
  finalSignoff: 'Final Signoff',
  characterCount: 'Character count',
  import: 'Import',
  maintenance: 'Maintenance',
  maintenanceDescription:
    'We are currently under maintenance, we will be back soon',
  deleteChecklistConfirmation:
    'Are you sure you want to remove this checklist?',
  decline: 'Decline',
  triggers: 'Triggers',
  trigger: 'Trigger',
  addTrigger: 'Add Trigger',
  itemAdded: 'Item Added',
  propertyChange: 'Property Change',
  action: 'Action',
  approveTransfer: 'Approve Transfer',
  resumeAfterSuspension: 'Resume After Suspension',
  declineTransfer: 'Decline Transfer',
  transferApproved: 'Transfer Approved',
  transferDeclined: 'Transfer Declined',
  permitTransferDeclined: 'Permit Transfer Declined',
  projectAdminInfo:
    'Project Admins are superusers that can manage all project settings',
  projectViewerInfo:
    'Project Viewers can view all project data but cannot make any changes or create any permits or checklists',
  operativeInfo:
    'Standard Users can create permits and checklists, and view project data',
  context: 'Context',
  intendedRecipient: 'Intended Recipient',
  permitTransferApproved: 'Permit Transfer Approved',
  reviewPermitTransfer: 'Review Permit Transfer',
  permitTransfer: 'Permit Transfer',
  requiresTransferApproval: 'Does Permit Require Transfer Approval',
  permitSuspension: 'Permit Suspension',
  noQuestionsText: 'No questions found.',
  noPermitRoleText: 'No permit roles found.',
  noPreStartTaskText: 'No pre-start tasks found.',
  folderRenamed: 'Folder renamed',
  folderCreated: 'Folder created',
  noChecklistText: 'No checklists found.',
  siteBox: 'SiteBox',
  chooseFromSitebox: 'Choose from SiteBox',
  tapOnAFileForSelection: 'Tap on a file for selection',
  selected: (selectedNumber: number) => `${selectedNumber} Selected`,
  noFilesFound: 'No files found',
  fileArchived: 'File archived',
  fileRestored: 'File restored',
  editFile: 'Edit File',
  copyLink: 'Copy Link',
  restore: 'Restore',
  diagram: 'Diagram',
  enableDiagrams: 'Enable diagrams',
  identifierProperty: 'Identifier Property',
  colorProperty: 'Color Property',
  enableDiagramDescription:
    'Enable diagrams to allow showing and manipulating register items on a plan or document.',
  identifierPropertyDescription:
    'Choose which property is used to identify items on the plan (usually a name).',
  colorPropertyDescription:
    'Choose a property to color-code items on the plan.',
  intendedRecepient: 'Intended recepient',
  recipient: 'Recipient',
  table: 'Table',
  addDiagram: 'Add Diagram',
  selectAnExistingDocumentOrUpload:
    'Select an existing document or upload a new one',
  documentAdded: 'Document added',
  invalidW3W: 'Invalid what3words address',
  w3wDoesNotExist: 'This what3words address does not exist',
  w3wNoInternetConnection:
    'To select a what3words location using the map, an internet connection is required. However, you can still manually enter the what3words location without internet access.',
  cannotEditTemplateMessage:
    "To make changes to these settings, please contact your organisation's Account Owner, or help@siteassist.co.uk",
  projectTemplatesHeaderMessage:
    'Click on a Permit Template to make project-specific configurations. Organisation level configurations must be updated by an Account Owner or SiteAssist Support.',
  copyLinkToClipboard: 'Copy Link to Clipboard',
  copyPermitLinkToClipboard: 'Copy link to the permit to the clipboard',
  copyChecklistLinkToClipboard: 'Copy link to the checklist to the clipboard',
  checklistQRCodeDescription:
    'Scan this QR code with your phone camera to open the checklist.',
  templateNotInProject: 'Template not active in project',
  annotation: 'Annotation',
  annotations: 'Annotations',
  openItemInNewTab: 'Open Item in new tab',
  editAnnotations: 'Edit Annotations',
  editAnnotation: 'Edit Annotation',
  annotationsUpdated: 'Annotations updated',
  transferPermitDisclaimer:
    'By signing this transfer you acknowledge that the permit will change ownership. You will no longer be the permit holder.',
  fileRenamed: 'File renamed',
  fileDownloaded: 'File downloaded',
  downloadFailed: 'Download failed',
  deleteFolder: 'Delete Folder',
  chooseFolder: 'Choose Folder',
  viewAllDocuments: 'View all Documents',
  addFileOrFolder: 'Add File or Folder',
  addFileOrFolderDescription: 'Create a new folder or upload a file',
  emptyFolder: 'This folder is empty',
  emptyFolderMessage:
    'You can upload documents or photos by tapping on the plus button in the top right corner',
  succesfullyMovedFile: 'Successfully moved file',
  addPermission: 'Add Permission',
  selectType: 'Select Type',
  permissionsUpdated: 'Permissions Updated',
  deleteGroupPermissionMessage:
    'Are you sure you want to remove this permission?',
  removeGroupPermissionsMessage:
    'Removing all permissions will delete the group from the list. Are you sure you want to continue?',
  organisationPermissions: 'Organisation Permissions',
  permitPermissions: 'Permit Permissions',
  registerPermissions: 'Register Permissions',
  checklistPermissions: 'Checklist Permissions',
  chooseUpTo3PropertiesToShowAsLabels:
    'Choose up to 3 properties to show as labels',
  selectChecklistsToLink: 'Select Checklists to Link',
  selectPermitsToLink: 'Select Permits to Link',
  switchToDiagramView: 'Switch to Diagram View',
  editItem: 'Edit Item',
  uploadADiagramToStartCreatingNewItems:
    'Upload a diagram to start creating new items.',
  forThisRegisterNewItemsNeedToBeCreatedInTheDiagramView:
    'For this register, new items need to be created in the diagram view.',
  toEditTheAnnotationsOfThisItemSwitchToDiagramView:
    'To edit the annotations of this item, switch to the Diagram View.',
  permitInitiationPermissionMessage:
    'When this permission is applied, a user must be in an Authorisation Group that has this permission enabled to raise the permit. Any Users not in one of these Auth Groups will not be able to raise this permit template.',
  checklistInitiationPermissionMessage:
    'When this permission is applied, a user must be in an Authorisation Group that has this permission enabled to raise the checklist. Any Users not in one of these Auth Groups will not be able to raise this checklist template.',
  dateGranularity: 'Date Granularity',
  comments: 'comments',
  comment: 'Comment',
  commentsTitle: 'Comments',
  addComment: 'Add Comment',
  send: 'Send',
  commentLowerCase: 'comment',
  newComment: 'New Comment',
  openAllQuestionComments: 'Open All Question Comments',
  beBriefedAs: 'Be briefed as',
  authGroupTemplateRolesText:
    'By selecting one of the below roles, this means that only the users added to this Authorisation Group will be able to be briefed onto this permit type under the specified role',
  commentsCount: (count: number) => `${count} Comment${count === 1 ? '' : 's'}`,
  openAllComments: 'Open All Comments',
  syncedFromSyncTray: 'Synced from sync Tray',
  warningText: 'Warning Text',
  imageObjectDetection: 'Image Object Detection',
  addWarning: 'Add Warning',
  deleteWarningConfirmation: 'Are you sure you want to delete this warning?',
  globalWarnings: 'Global Warnings',
  warnings: 'Warnings',
  warningType: 'Warning Type',
  questionSpecificWarnings: 'Question Specific Warnings',
  NumberShort: 'No.',
  questionTitle: 'Question Title',
  source: 'Source',
  editWarning: 'Edit Warning',
  warningMessage: 'You must finish create question before adding a warning',
  warningLostDataMessageConfimation:
    'Changing this will result in loss of warning data for this question. Are you sure you want to continue?',
  warningSettings: 'Warning Settings',
  reportAsIncorrect: 'Report as incorrect',
  warningReportedMessage: 'This warning has been reported',
  warningReportedAlertMessage: `Thank you for your feedback. We're reviewing it to improve the system.`,
  hasPermitLinks: 'Has Permit Links',
  viewAllRegisters: 'View All Registers',
  noRegistersFound: 'No registers found',
  noItemsFound: 'No items found',
  filterRegisters: 'Filter Registers',
  registerItemArchived: 'Register Item Archived',
  registerItemUnarchived: 'Register Item Unarchived',
  searchByRegisterName: 'Search by register name',
  timeTravelerMessage:
    "It looks like your device's clock is set to the past. Unfortunately, our app hasn't invented time travel compatibility yet.\n\nPlease reset your date to the present to continue.",
  customColours: 'Custom colours',
  noColours: 'No colours',
  filterValue: 'Filter Value',
  noDateSelected: 'No date selected',
  lockedAfterCreationField: 'Locked after creation Field',
  warningConfig: 'Warning Config',
  clonedPermitDetected: 'Cloned Permit Detected',
  warningDetected: 'Warning Detected',
  seeDetails: 'See Details',
  duplicateImagesDetected: 'Duplicate Images Detected',
  hazardDetected: 'Hazard Detected',
  youCantPerformThisAction: 'You can not perform this action!',
  emptyQuestionsMessage:
    'You cannot activate this version without <span class="text-bold">{0} Questions</span>. Please add questions to the relevant section{1} before activating this version.',
  templateQuestionsText: (
    noPermitHolderQuestions: boolean,
    noAuthPersonQuestions: boolean,
  ) => {
    return localizedStrings.formatString(
      localizedStrings.emptyQuestionsMessage,
      `${noPermitHolderQuestions ? localizedStrings.permitHolder : ''} ${
        noPermitHolderQuestions && noPermitHolderQuestions ? ' and ' : ''
      } ${noAuthPersonQuestions ? localizedStrings.authorisedPerson : ''}`,
      `${noPermitHolderQuestions && noAuthPersonQuestions ? 's' : ''}`,
    ) as string
  },
  itemArchived: 'Item Archived',
  approvalRequestInfo: 'Issue the permit to begin work on site',
  approvalRequestInfoDraft: `You've already begun the approval process for this permit. To continue, click 'Approve'.`,
  extensionRequested: 'Extension Requested',
  rejectExtension: 'Reject Extension',
  labels: 'Labels',
  postPermitChecksDescription:
    'Post Permit Checks must be done to close the permit.',
  tags: 'Tags',
  newTag: 'New Tag',
  createNewTag: 'Create new tag',
  editTag: 'Edit Tag',
  tagCreated: 'Tag created',
  tagUpdated: 'Tag updated',
  deleteProcessTagMessage: 'Are you sure you want to delete this tag?',
  tagArchived: 'Tag archived',
  recent: 'Recent',
  allTags: 'All Tags',
  noTagsFound: 'No tags found',
  invalidPermissions: 'Invalid Permissions',
  permitNoPermission: 'You do not have permissions to view this permit',
  calculated: 'Calculated',
  itemDetails: 'Item Details',
  errorUploading: 'Error uploading',
  editInMap: 'Edit in Map',
  preStartTaskConditionalLogicDescription:
    'Set the conditions that will trigger this Pre-Start Task',
  questionSet: 'Question Set',
  preStartTaskNoRuleText:
    'There is no rules associated with this Pre-Start Task.',
  preStartTaskStageChangeWarning:
    'Question set change will remove all rules. Are you sure you want to continue?',
  conditionsChipText: (length: number) =>
    `Conditions: ${length} rule${length !== 1 ? 's' : ''}`,
  drawOnMapRequiredErrorMessage: 'Please draw your location on the map',
}

const Strings: Record<SupportedLanguagesType, StringsType> = {
  en: enBaseStrings,
}

export const localizedStrings = new LocalizedStrings(Strings)
